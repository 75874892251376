import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useInView } from "react-intersection-observer"
import descriptionData from "../content/description.json"
import ProductContainer from "../components/productContainer"
import buildSchema from "../script/buildschema"
import { Helmet } from "react-helmet"

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const SalesDress = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  // const data = props.data.allAirtable.edges
  const {
    ref,
    inView: fixHeader,
    entry,
  } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  buildSchema(edges)

  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.collectivedress.com/sales-dresses"
        />
        <title>{descriptionData.sales.pageTitle}</title>
        <meta name="description" content="Browse our impressive collection of discounted dresses on sale! Find trendy party dresses, elegant evening gowns, comfortable everyday dresses, and more. Unleash your inner fashionista without breaking the bank by shopping with us!"/>         
      </Helmet>
      <Seo description={descriptionData.sales.pageDescription} />

      <div className="descriptions-container container px-12 mx-auto max-w-screen-xl">
        <h1 class="text-2xl mt-5 mb-5 font-bold">
          {descriptionData.sales.heading}
        </h1>
        <p className="hidden md:block">{descriptionData.sales.description1}</p>
        <p className="text-l mt-5 mb-5 font-bold">
          Website data refreshes every Monday, Wednesday, Friday
        </p>
      </div>

      <div className="product-container grid grid-cols-2 gap-2 md:grid-cols-3 max-w-screen-xl mx-auto">
        <ProductContainer edges={edges} dressType="Sales"/>
      </div>
      <p className="lg:hidden">{descriptionData.sales.description1}</p>
    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "dress_sales" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
            product_images_2nd
            product_images_3rd
            product_images_4th
            product_images_5th
            product_images_6th
          }
        }
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={descriptionData.sales.pageTitle}
    description={descriptionData.sales.pageDescription}
  />
)

export default SalesDress
